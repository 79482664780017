import { memo } from 'react';
import Loadable from 'react-loadable';
import LoadingRoutes from 'utils/Components/LoadingRoutes';
import { refactorRoute } from './routeConstant';

const Loading = memo(() => {
    return <LoadingRoutes />;
});
Loading.displayName = 'Loading';

const LoaderBuilder = (loader: () => Promise<any>) =>
    Loadable({ loader, loading: Loading });

const patientDetailRoutes = [
    {
        name: 'Hanh Chinh',
        path: refactorRoute.PATIENT_DETAIL_HANH_CHINH,
        component: LoaderBuilder(() => import('view/PatientDetail/HanhChinh')),
    },
    {
        name: 'Benh Su',
        path: refactorRoute.PATIENT_DETAIL_BENH_SU,
        component: LoaderBuilder(() => import('view/PatientDetail/BenhSu')),
    },
    {
        name: 'Chan Doan',
        path: refactorRoute.PATIENT_DETAIL_CHAN_DOAN,
        component: LoaderBuilder(() => import('view/PatientDetail/ChanDoan')),
    },
    // {
    //     name: 'Chan Doan',
    //     path: refactorRoute.DONOR_DETAIL_CHAN_DOAN,
    //     component: LoaderBuilder(() => import('view/DonorDetail/ChanDoan')),
    //     children: [
    //         {
    //             name: 'Default',
    //             path: refactorRoute.DONOR_DETAIL_CHAN_DOAN,
    //             component: () => (
    //                 <Redirect to={refactorRoute.DONOR_DETAIL_CHAN_DOAN_CHUNG} />
    //             ),
    //         },
    //         {
    //             name: 'Chan Doan Chung',
    //             path: refactorRoute.DONOR_DETAIL_CHAN_DOAN_CHUNG,
    //             component: LoaderBuilder(
    //                 () => import('view/DonorDetail/ChanDoan/ChanDoanChung')
    //             ),
    //         },
    //         {
    //             name: 'Chan Doan Tu Vong',
    //             path: refactorRoute.DONOR_DETAIL_CHAN_DOAN_TU_VONG,
    //             component: LoaderBuilder(
    //                 () => import('view/DonorDetail/ChanDoan/ChanDoanTuVong')
    //             ),
    //         },
    //     ],
    // },
    // {
    //     name: 'Dien Tien Lam Sang',
    //     path: refactorRoute.DONOR_DETAIL_DIEN_TIEN_LAM_SANG,
    //     component: LoaderBuilder(
    //         () => import('view/DonorDetail/DienTienLamSang')
    //     ),
    //     children: [
    //         {
    //             name: 'Default',
    //             path: refactorRoute.DONOR_DETAIL_DIEN_TIEN_LAM_SANG,
    //             component: () => (
    //                 <Redirect to={refactorRoute.DONOR_DETAIL_LAM_SANG} />
    //             ),
    //         },
    //         {
    //             name: 'Lam Sang',
    //             path: refactorRoute.DONOR_DETAIL_LAM_SANG,
    //             component: LoaderBuilder(
    //                 () => import('view/DonorDetail/DienTienLamSang/LamSang')
    //             ),
    //         },
    //     ],
    // },
];

export default patientDetailRoutes;
